import Particles, { initParticlesEngine } from "@tsparticles/react";
import { useEffect, useMemo, useState, useRef } from "react";
import './particles.css';
import { loadSlim } from "@tsparticles/slim"; 



const ParticlesComponent = (props) => {

  const [init, setInit] = useState(false);
  const containerRef = useRef(null);

 
  useEffect(() => {
    initParticlesEngine(async (engine) => {
     
      await loadSlim(engine);
      
    }).then(() => {
      setInit(true);
    });
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.style.position = "relative";
    }
  }, [containerRef]);



  const particlesLoaded = (container) => {
    console.log(container);
  };

  // const containerStyle = {
  //   position: "relative",
  //   width: "100%",
  //   height: "100%",
  //   background: "rgba(30, 47, 151, 0)", // Fully transparent background
  // };


  const options = useMemo(
    () => ({
      fullScreen: {
        enable: false,
      },
      background: {
        color: {
          value: "transparent", // Set color to transparent
        },
      },
      fpsLimit: 120,
      interactivity: {
        events: {
          onClick: {
            enable: true,
            mode: "repulse",
          },
          onHover: {
            enable: true,
            mode: 'grab',
          },
        },
        modes: {
          push: {
            distance: 100,
            duration: 15,
          },
          grab: {
            distance: 150,
          },
        },
      },
      particles: {
        color: {
          value: "#000",
        },
        links: {
          color: "#000",
          distance: 150,
          enable: true,
          opacity: 0.3,
          width: 1,
        },
        move: {
          direction: "none",
          enable: true,
          outModes: {
            default: "bounce",
          },
          random: true,
          speed: 3,
          straight: false,
        },
        number: {
          density: {
            enable: true,
          },
          value: 350,
        },
        opacity: {
          value: 1.0,
        },
        shape: {
          type: "circle",
        },
        size: {
          value: { min: 1, max: 4 },
        },
      },
      detectRetina: true,
    }),
    [],
  );


  return (
          <Particles id={props.id} init={particlesLoaded} options={options} />
       
  );
};

export default ParticlesComponent;


